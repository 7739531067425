import React from "react"
import { useParams } from "react-router";
import { AccountSummaryRequest } from "../../../../services/accountService/commands";
import { useFindAdjustmentsByPeriodQuery } from "../../../../services/accountService/account-api";
import { Stack, Typography } from "@mui/material";
import { formatNumber } from "@progress/kendo-intl";

function ApprovedAdjustmentsCard(){
    const {center, month, year} = useParams();
    const {data} = useFindAdjustmentsByPeriodQuery(new AccountSummaryRequest(center!, Number(month!), Number(year!)));
    const approvedAmount = data === undefined ? 0 : data.reduce((acc, item)=>acc+item.adjustmentsApproved!,0);
    return <React.Fragment>
        <Stack alignItems='center'>
            <Typography variant='subtitle1' className='psn-green'>Approved Adjustments</Typography>
            <Typography variant='subtitle1' className='psn-green'>{formatNumber(approvedAmount,'c')}</Typography>
        </Stack>
    </React.Fragment>
}
export{ApprovedAdjustmentsCard}