import {  Stack, Typography } from "@mui/material"
import React from "react"
import { useNavigate, useParams } from "react-router";
import { AdjustmentGrid } from "../features/outOfPeriod/AdjustmentGrid";
import { CenterNameView } from "../../../components/orgComponents";
import { AccountCenterSummaryCommands, AccountLookup } from "../features";
import { ApprovedAdjustmentsCard } from "../features/outOfPeriod/ApprovedAdjustmentsCard copy";
import { UnApprovedAdjustmentsCard } from "../features/outOfPeriod/UnApprovedAdjustmentsCard";


function RevRecOutOfPeriod(){
    const {center,month,year} = useParams();
    
    return <React.Fragment>
        <Stack direction ='row' justifyContent='space-between' marginBottom='10px'>
            <Stack direction='row' gap={1}>
                <CenterNameView centerId={center!}/> 
                <Typography className='psn-green' variant="h6">{month + '-' + year}</Typography>
            </Stack>
            <AccountLookup/>
            <AccountCenterSummaryCommands/>
        </Stack>
        <Stack direction='row' gap={3}>
            <ApprovedAdjustmentsCard/>
            <UnApprovedAdjustmentsCard/>
        </Stack>
        <AdjustmentGrid/>
    </React.Fragment>
}
export {RevRecOutOfPeriod}