import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import {
  toggleIndividualTypeModal,
  toggleVarianceSelectedIdentType,
} from "../../../../../app/slices/VarianceModuleSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { VarianceInitiatePatientSearchDialog } from "../patient/VarianceInitiatePatientSearchDialog";
import { VarianceInitiateIdentification } from "./VarianceInitiateIndividual";
import { editVarianceIndividual } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

function VarianceInitiateIndividualType() {
  const modalOpen = useAppSelector(
    (state) => state.varianceModule.VarianceIndividualTypeModalOpen
  );
  const individualType = useAppSelector(
    (state) => state.varianceModule.VarianceSelectedIndividualType
  );
  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <Dialog open={modalOpen} maxWidth="sm">
        <DialogTitle>Identification Type</DialogTitle>
        <DialogContent>
          <Select
            label="Identification Type"
            value={individualType}
            name="individualType"
            onChange={(e) => {
              dispatch(editVarianceIndividual({name: e.target.name, value: e.target.value}));       
              dispatch(
                toggleVarianceSelectedIdentType(e.target.value)
              );
              dispatch(toggleIndividualTypeModal());
            }}
            sx={{ width: "200px", maxWidth: "100%" }}
            size="small"
          >
            <MenuItem value="Patient">Patient</MenuItem>
            <MenuItem value="Visitor">Visitor</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              dispatch(toggleIndividualTypeModal());
              dispatch(toggleVarianceSelectedIdentType(""));
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <VarianceInitiatePatientSearchDialog />
      <VarianceInitiateIdentification />
    </React.Fragment>
  );
}
export { VarianceInitiateIndividualType };
