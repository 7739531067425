import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { GridButton } from "../../../../../../../components";
import { IncidentAssignUsersCommand } from "../../../../../../../services/varianceService/commands/IncidentAssignUsersCommand";
import { useIncidentAssignUsersMutation } from "../../../../../../../services/varianceService/variance-api";
import { Alert, Snackbar } from "@mui/material";
import { AssignedUser } from "../../../../../../../services/varianceService/model/varianceRequest/AssignedUser";
import { useMyProfileQuery } from "../../../../../../../services/profileService/profile-api";

function LookupIncidentAssignUserGridCommand(props: GridCellProps) {
  const dispatch = useAppDispatch();
  const [assign, status] = useIncidentAssignUsersMutation();
  const variance = useAppSelector(
    (state) => state.varianceRequest.varianceRequest
  );
  const [assignedUsers, setAssignedUser] = useState(variance.assignedUsers);
  const profile = useMyProfileQuery();
  const onSelect = () => {
    let assignedUser: AssignedUser = {
      upn: props.dataItem?.upn,
      displayName: props.dataItem?.identity,
      email: props.dataItem?.email,
      assignedBy: profile.data?.identity ?? "",
      assignedByEmail: profile.data?.email ?? "",
      isClosed: false,
    };
    setAssignedUser([...assignedUsers, assignedUser]);
    dispatch({ type: "varianceRequest/addAssignedUser", payload: { value: assignedUser } });
    let command = new IncidentAssignUsersCommand(variance.id, assignedUser);
    assign(command);
  };
  const [notify, setNotify] = useState(false);
  useEffect(() => {
    setNotify(status.isSuccess);
  }, [status.isSuccess]);

  return (
    <React.Fragment>
      <GridButton disabled={assignedUsers?.some(p => p.upn === props.dataItem?.upn)}
        className="ms-1 mt-1"
        variant="outlined"
        color="primary"
        onClick={() => {
          onSelect();
        }}
      >
        {assignedUsers?.some(p => p.upn === props.dataItem?.upn) ? "Assigned" : "Select"}
      </GridButton>
      <Snackbar
        open={notify}
        onClose={() => setNotify(false)}
        color="success"
        autoHideDuration={6000}
        message={"Incident Assigned to " + props.dataItem?.identity}
      />
      <Snackbar open={status.isError}>
        <Alert color="error">
          {"An error has occured: " + JSON.stringify(status.error)}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export { LookupIncidentAssignUserGridCommand };
