import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { formatNumber } from "@telerik/kendo-intl";
import { TextField, Stack, InputAdornment, Button, Typography } from "@mui/material";
import { useCalculateRespMutation } from "../../../../../services/calculatorService/calculator-Api";
import { Benefits } from "../../../../../services/calculatorService/model/patRespCalc/Benefits";
import { PatientResponsibility } from "../../../../../services/calculatorService/model";
import { AmountDue } from "../../../../../services/calculatorService/model/patRespCalc/AmountDue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateResponsibility } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { PatientRespChangeNote } from "./PatientRespChangeNote";
import { togglePatientRespNoteAddModal } from "../../../../../app/slices/VerificationModuleSlice";
import { store } from "../../../../../app/store";

function PatientOutOfPocket(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state)=>state.verification.verification);
    
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    
    const [calculation] = useCalculateRespMutation();
    const calculate=()=>{
        const benefits = new Benefits(verification.benefits.deductible,verification.benefits.deductibleMet,verification.benefits.outOfPocket,
            verification.benefits.outOfPocketMet,verification.benefits.coPay,verification.benefits.coInsurance,
            verification.benefits.dedApplyToOOP,verification.benefits.coPayApplyToOOP,verification.benefits.paidToPhysician,
            verification.benefits.uncoveredAmount);
        const command = new PatientResponsibility()
        command.indvBenefits = benefits;
        command.totalExpectedReimbursment = verification.procedures.filter(p => p.selfPay != true)?.map(i => i.reimbursement).reduce((a,b) => a+ b,0);
        command.totalSelfPayReimbursment = verification.procedures.filter(p => p.selfPay === true)?.map(i => i.reimbursement).reduce((a,b) => a+ b,0);
        command.hasSecondaryIns = schedule.data!.insurances.length > 1 ? true : false

        calculation(command).unwrap().then(fullfilled => {
            const newAmountDue = new AmountDue(fullfilled.amountDue.deductibleDue??0, fullfilled.amountDue.coInsuranceDue??0,
                fullfilled.amountDue.coPayDue??0,fullfilled.amountDue.totalPatientDue??0, '', '');

            const hasChanged = verification.patientResponsibility.deductibleDue !== newAmountDue.deductibleDue ||
                               verification.patientResponsibility.coInsuranceDue !== newAmountDue.coInsuranceDue ||
                               verification.patientResponsibility.copayDue !== newAmountDue.coPayDue ||
                               verification.patientResponsibility.collectFromPatient !== newAmountDue.totalPatientDue;

            if (hasChanged && verification.patientResponsibility.collectFromPatient > 0 ) {
                dispatch(togglePatientRespNoteAddModal());
                const noteAddedUnsubscribe = store.subscribe(() => {
                    const state = store.getState();
                    if (!state.verificationModule.PatientRespNoteAddModal) {
                        noteAddedUnsubscribe();
                        newAmountDue.changeNote = state.verificationModule.PatientRespChangeNote;
                        newAmountDue.changeNoteCategory = state.verificationModule.PatientRespChangeNoteCategory;
                    }
                });
            }
                dispatch(updateResponsibility(newAmountDue));
        });
    }

    return <React.Fragment>
        <Typography variant='h6' className="psn-green" margin='10px'>Patient Responsibility</Typography>
        <Stack direction='row' gap={1} sx={{ml:2}}>
            <TextField label='Deductible Due' sx={{width:'150px'}} value={formatNumber(verification.patientResponsibility.deductibleDue,'n2')} size='small' 
                InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Co-Insurance Due' sx={{width:'150px'}} value={formatNumber(verification.patientResponsibility.coInsuranceDue,'n2')} size='small'
                 InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Co-Pay Due' sx={{width:'150px'}} value={formatNumber(verification.patientResponsibility.copayDue,'n2')} size='small'
                InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Confirmed Payments' sx={{width:'150px'}} value={formatNumber(verification.benefits.paidToPhysician,'n2')} size='small'
                InputProps={{startAdornment:<InputAdornment position="start">$</InputAdornment>}}/>
            <TextField label='Uncovered Amount' sx={{width:'150px'}} value={formatNumber(verification.benefits.uncoveredAmount,'n2')} size='small'
                InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <TextField label='Total Patient Resp' sx={{width:'150px'}} value={formatNumber(verification.patientResponsibility.collectFromPatient,'n2')} size='small'
                InputProps={{startAdornment:<InputAdornment position='start'>$</InputAdornment>}}/>
            <Button size="large" variant="outlined" onClick={()=>calculate()}><FontAwesomeIcon icon='calculator'/></Button>
        </Stack>
            <PatientRespChangeNote/>
    </React.Fragment>
}

export {PatientOutOfPocket}