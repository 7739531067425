import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { useServiceListQuery } from "../../../../../services/orgService/org-api";
import { DefaultProcedure } from "../../../../../services/scheduleService/model/schedule/Procedure";
import { Button, Dialog, DialogContent, MenuItem, Stack, TextField, Typography, Alert } from "@mui/material";
import { toggleProcedureCreateDialog } from "../../../../../app/slices/ScheduleModuleSlice";
import { Create } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { createProcedure } from "../../../../../services/scheduleService/model/ScheduleSlice";
import dayjs from "dayjs";

type ownProps={
    center:string;
}

function ProcedureCreate(props:ownProps){
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state)=>state.scheduleModule.ProcedureCreateDialog);
    const services = useServiceListQuery(props.center);
    const servicesCopy = services.data === undefined?[]:[...services.data];
    const [procedure, setProcedure] = useState(DefaultProcedure())
    const [error, setError] = useState<string | null>(null);
    const hasError = error !== null;
    const [endTimeMinDate, setEndTimeMinDate] = useState<dayjs.Dayjs | null>(null);

    const handleSave = () => {
        if (procedure.startTime && procedure.endTime && procedure.startTime > procedure.endTime) {
            setError("End Time cannot precede Start Time.");
            return;
        }
        dispatch(createProcedure(procedure));
        dispatch(toggleProcedureCreateDialog());
    };

    const handleStartTimeChange = (date: dayjs.Dayjs | null) => {
        if (date && (!procedure.endTime || date.isBefore(dayjs(procedure.endTime)))) {
            setError(null);
            setProcedure({ ...procedure, startTime: date.toDate() });
            setEndTimeMinDate(date.add(1, 'minute'));
        } else {
            setError('Start time must be before end time');
        }
    };

    const handleEndTimeChange = (date: dayjs.Dayjs | null) => {
        if (date && (!procedure.startTime || date.isAfter(dayjs(procedure.startTime)))) {
            setError(null);
            setProcedure({ ...procedure, endTime: date.toDate() });
        } else {
            setError('End time must be after start time');
        }
    };

    return <React.Fragment>
        <Stack direction='row' justifyContent='flex-end' marginTop='5px'>
            <Button variant='outlined' startIcon={<Create/>} 
                onClick={()=>dispatch(toggleProcedureCreateDialog())}>Add Service</Button>
        </Stack>
        
        <Dialog open={isOpen}>
            <DialogContent>
                <Stack margin={'10px'}>
                {error && <Alert severity="error">{error}</Alert>}
                </Stack>
                <Stack direction='row' gap={1} marginBottom='10px'>
                    <TextField label='Room' name='room' value={procedure?.room} 
                        onChange={(e)=>setProcedure({...procedure,room:e.target.value})}/>
                </Stack>

                <Stack gap={1} justifyContent='flex-start'>
                    <TextField label='Service' sx={{width:'225px'}} value={procedure.serviceCode} select
                        onChange={(e)=> setProcedure({...procedure,serviceCode:e.target.value})}>
                        {servicesCopy?.sort((a,b)=>a.code>b.code?1:-1).map(i =>
                            (<MenuItem key={i.code} value={i.code}>{i.code}</MenuItem>))}
                    </TextField>
                    <Typography variant='body2'>{servicesCopy.find(p => p.code == procedure.serviceCode)?.description}</Typography>
                </Stack>


                <Stack direction='row' gap={1} marginTop='10px' paddingTop='10px' borderTop='solid grey 3px'>
                    <DateTimePicker label='Start Time' value={dayjs(procedure.startTime)}
                            onChange={handleStartTimeChange} minDate={dayjs()}/>
                    <DateTimePicker label='End Time' value={dayjs(procedure.endTime || null)}
                            onChange={handleEndTimeChange} minDate={endTimeMinDate || undefined}/>
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                    <Button color='secondary' onClick={()=>dispatch(toggleProcedureCreateDialog())}>Cancel</Button>
                    <Button onClick={handleSave} disabled={hasError}>Save</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </React.Fragment>
}

export {ProcedureCreate}