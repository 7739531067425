import React from "react"
import { useNavigate, useParams } from "react-router";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { Button, Stack } from "@mui/material";
import { CenterNameChipView } from "../../../../../components/orgComponents/CenterNameChipView";
import { PhysicianChipView } from "../../../../../components/encounterItems";
import { RevRecSaveCommand } from "./RevRecSaveCommand";
import { accountApi } from "../../../../../services/accountService/account-api";
import { useAppDispatch } from "../../../../../app/hooks";

function RevRecCommands(){
    const {id} = useParams();
    const schedule = useCaseFindQuery(id!);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const invalidateTags=()=>{dispatch(accountApi.util.invalidateTags(['AdjustmentSummary']))}

    return <React.Fragment>
        <Stack direction='row' justifyContent='space-between' gap={1}>
            <CenterNameChipView centerId={schedule.data?.center??''}/>
            <PhysicianChipView/>
            <Stack direction='row' gap={1}>
                <RevRecSaveCommand/>
                <Button variant="outlined" color='success' onClick={()=>{navigate(-1);invalidateTags()}}>Go Back</Button>
            </Stack>
        </Stack>
    </React.Fragment>
}
export{RevRecCommands}