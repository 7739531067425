import React from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { toggleIdentificationDetailsDialog } from "../../../../../app/slices/VarianceModuleSlice";

function VarianceInitiatePatientManualEntry() {
  const dispatch = useAppDispatch();

  return (
    <React.Fragment>
      <a
        href="#"
        onClick={() => {
          dispatch(toggleIdentificationDetailsDialog());
        }}
        style={{ fontSize: "small" }}
      >
        Manual Entry
      </a>
    </React.Fragment>
  );
}

export { VarianceInitiatePatientManualEntry };
