import { AssignedUser } from "../model/varianceRequest/AssignedUser";

export class IncidentAssignUsersCommand {
    varianceId: string;
    assignedUsers: AssignedUser[] = [];
    constructor(varianceId: string, assignedTo: AssignedUser) {
        this.varianceId = varianceId;
        this.assignedUsers.push(assignedTo);

    }
}