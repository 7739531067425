import React from "react"
import { Loading } from "../../shared/features/Loading";
import { useMyProfileQuery } from "../../../services/profileService/profile-api";
import { Stack, Typography} from "@mui/material";
import { VobStatusCard } from "../features/ReportCards/VobStatusCard";
import { VobProductivityCard } from "../features/ReportCards/VobProductivityCard";
import { RefundsIncomingCard } from "../features/ReportCards/RefundsIncomingCard";
import { PatientCollectionsCard } from "../features/ReportCards/PatientCollectionsCard";
import { LowContributionCard } from "../features/ReportCards/LowContributionCard";
import { ScheduledCasesCard } from "../features/ReportCards/ScheduledCasesCard";
import { IncidentSummaryCard } from "../features/ReportCards/IncidentSummaryCard";
 
function ReportDashboard(){
    const {isLoading} = useMyProfileQuery();
    return <React.Fragment>
        {isLoading === true ?
        <Loading/>:
        <div>
        <Typography variant='h4' className='psn-green' sx={{ paddingTop:'20px', paddingBottom:'10px'}}>Revenue Cycle Reports</Typography>
        <Stack direction='row' spacing={4} justifyContent='flex-start' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
            <VobStatusCard/>
            <VobProductivityCard/>
            <RefundsIncomingCard/>
            <PatientCollectionsCard/>
        </Stack>
        <Stack direction='row' spacing={4} justifyContent='flex-start' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
            <LowContributionCard/>
            <ScheduledCasesCard/>
        </Stack>
        <hr className='psn-green'/>
        <Stack>
            <Typography variant='h4' className='psn-green' sx={{ paddingTop:'20px', paddingBottom:'10px'}}>Incident Reports</Typography>
        <Stack direction='row' spacing={4} justifyContent='flex-start' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
            <IncidentSummaryCard/>
            </Stack>
        </Stack>
{/*         <Container maxWidth="sm">    
           <Grid container spacing={10}>
                <Grid item sm={6}>
                    <Box sx={{ boxShadow: 1, borderRadius: 6 }}>
                    <Card className='card-img-body center-align' sx={{height:'200px', borderRadius: 6}}>
                        <CardHeader title="Finance" className='psn-green' sx={{paddingBottom:'1px', borderBottom:'1px solid grey'}}/>
                        <CardContent sx={{display:'flex', textAlign:'left'}}>
                            <Stack>
                                <Link to={'/VolumeAndRevenue'} className='psn-blue' style={{paddingBottom:'5px'}}>Monthly Revenue & Volume</Link>
                                <Link to={'/CaseDetail'} className='psn-blue' style={{paddingBottom:'5px'}}>Case Detail</Link>
                                <Link to={'/WeeklyProjections'} className='psn-blue'>Weekly Projections</Link>
                            </Stack>
                        </CardContent>
                    </Card>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box sx={{ boxShadow: 1, borderRadius: 6 }}>
                    <Card className='card-img-body center-align' sx={{height:'200px', borderRadius: 6}}>
                        <CardHeader title="Facility Operations" className='psn-green' sx={{paddingBottom:'1px', borderBottom:'1px solid grey'}}>
                        </CardHeader>
                        <CardContent sx={{display:'flex', textAlign:'left'}}>
                            <Stack direction='column'>
                                <Link to={'/FacilityScorecard'} className='psn-blue' style={{paddingBottom:'5px'}} >Facility Scorecard</Link>
                                <Link to={'/CancelledCases'} className='psn-blue' style={{paddingBottom:'5px'}} >Cancelled Cases</Link>
                                <Link to={'/FirstStartsOnTime'} className='psn-blue' style={{paddingBottom:'5px'}}>First Starts on Time</Link>
                                <Link to={'/CpsiORMinutes'} className='psn-blue'>CPSI OR Minutes</Link>
                            </Stack>
                        </CardContent>
                    </Card>
                    </Box>
                </Grid>  
                <Grid item sm={(6)}>                    
                    <Box sx={{ boxShadow: 1, borderRadius: 6 }}>
                    <Card className='card-img-body center-align'sx={{height:'200px', borderRadius: 6}}>
                        <CardHeader title="Revenue Cycle" className='psn-green' sx={{paddingBottom:'1px', borderBottom:'1px solid grey'}}>
                        </CardHeader>
                        <CardContent sx={{display:'flex', textAlign:'left'}}>
                            <Stack>
                                <Link to={'/VolumeAndRevenue'} className='psn-blue'>Charge Error Report</Link>
                                <Link to={'/RadAuthOutstanding'} className='psn-blue'>Radiology Auth Outstanding</Link>
                                <Link to={'/VOBStatus'} className='psn-blue'>VOB Status</Link>
                                <Link to={'/VOBProductivity'} className='psn-blue'>VOB Productivity</Link>
                                <Link to={'/RefundsIncoming'} className='psn-blue'>Refunds Incoming</Link>
                            </Stack>
                        </CardContent>
                    </Card>
                    </Box>
                </Grid>    
                <Grid item sm={(6)}>
                    <Box sx={{ boxShadow: 1, borderRadius: 6 }}>
                    <Card className='card-img-body center-align' sx={{height:'200px', borderRadius: 6}}>
                        <CardHeader title="Supply Chain" className='psn-green' sx={{paddingBottom:'1px', borderBottom:'1px solid grey'}}>
                        </CardHeader>
                        <CardContent sx={{display:'flex', textAlign:'left'}}>
                            <Stack>
                                <Link to={'/CpsiPurchaseOrders'} className='psn-blue' style={{paddingBottom:'5px'}}>CPSI Purchase Orders</Link>
                                <Link to={'/CpsiCharges'} className='psn-blue'>CPSI Charges</Link>
                            </Stack>
                        </CardContent>
                    </Card>
                    </Box>
                </Grid>       
            </Grid>  
        </Container> */}
        </div>
        }
    </React.Fragment>
}

export {ReportDashboard}