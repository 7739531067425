import {
  FormGroup,
  Box,
  TextField,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";
import {
  FollowUp,
  DefaultFollowUp,
} from "../../../../../services/varianceService/model/varianceRequest/FollowUp";
import { editFollowUp } from "../../../../../services/varianceService/model/varianceRequest/VarianceRequestSlice";

function ImmediateFollowUp() {
  const dispatch = useAppDispatch();
  const incident = useAppSelector(
    (state) => state.varianceRequest.varianceRequest);
  const [followUp, setFollowUp] = useState(incident.followUp);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newFollowUp: FollowUp = followUp || DefaultFollowUp();
    newFollowUp = {
      ...newFollowUp,
      [e.target.name]:
        (e.target as HTMLInputElement).type === "checkbox"
          ? (e.target as HTMLInputElement).checked
          : (e.target as HTMLInputElement).value,
    };
    setFollowUp(newFollowUp);
  };
  const { data } = useMyProfileQuery();
  const canEdit =():boolean=>{
    if(incident.status === "Complete"){return false;}
    if(data?.role === 'Admin'){return true;}
    if(data?.role === 'Quality Team'){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentQualityTeam') != undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentAdmin') != undefined){return true;}
    if(data?.claimsAccess.find(p => p.code === 'IncidentEdit' && incident.status === "Started") != undefined){return true;}
    if(incident?.assignedUsers.find(p => p.upn === data?.upn) && incident.status === "Assigned"){return true;}
    return false;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 3,
          mr: 3,
          mb: 3,
          mt: 3,
        }}
      >
        <FormGroup>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            <Stack
              direction="row"
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={1}
            >
              <FormGroup>
                <FormControlLabel
                  label="Physician Notified?"
                  disabled={!canEdit()}
                  control={
                    <Checkbox
                      checked={followUp?.physicianNotified}
                      name="physicianNotified"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editFollowUp({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {followUp?.physicianNotified && (
                  <TextField
                    id="standard-basic"
                    name="physicanNotifiedName"
                    label="Physician Name"
                    variant="standard"
                    disabled={!canEdit()}
                    value={followUp?.physicanNotifiedName}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editFollowUp({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label="Physician Examined patient?"
                  disabled={!canEdit()}
                  control={
                    <Checkbox
                      checked={followUp?.physicianExamined}
                      name="physicianExamined"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editFollowUp({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {followUp?.physicianExamined && (
                  <TextField
                    id="standard-basic"
                    name="physicianExaminedName"
                    label="Physician Name"
                    variant="standard"
                    disabled={!canEdit()}
                    value={followUp?.physicianExaminedName}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editFollowUp({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label="Lab / Diagnostic tests ordered?"
                  disabled={!canEdit()}
                  control={
                    <Checkbox
                      checked={followUp?.labOrdered}
                      name="labOrdered"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editFollowUp({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {followUp?.labOrdered && (
                  <TextField
                    id="standard-basic"
                    name="labList"
                    label="Lab List"
                    variant="standard"
                    disabled={!canEdit()}
                    value={followUp?.labList}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editFollowUp({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label="Family Notified?"
                  disabled={!canEdit()}
                  control={
                    <Checkbox
                      checked={followUp?.familyNotified}
                      name="familyNotified"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editFollowUp({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {followUp?.familyNotified && (
                  <TextField
                    id="standard-basic"
                    name="familyNotifiedName"
                    label="Famlily Member Name"
                    variant="standard"
                    disabled={!canEdit()}
                    value={followUp?.familyNotifiedName}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        editFollowUp({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label="Charge Nurse / Supervisor Notified?"
                  disabled={!canEdit()}
                  control={
                    <Checkbox
                      checked={followUp?.chargeNurseNotified}
                      name="chargeNurseNotified"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          editFollowUp({
                            name: e.target.name,
                            value: e.target.checked as boolean,
                          })
                        );
                      }}
                    />
                  }
                />
                {followUp?.chargeNurseNotified && (
                  <TextField
                    id="standard-basic"
                    name="chargeNurseName"
                    label="Charge Nurse / Supervisor Name"
                    variant="standard"
                    disabled={!canEdit()}
                    value={followUp?.chargeNurseName}
                    onChange={(e) => {
                      handleChange(e);
                      handleChange(e);
                      dispatch(
                        editFollowUp({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                )}
              </FormGroup>
            </Stack>
            <Stack marginTop={"50px"}>
              <label style={{ fontSize: "16px" }}>Other Actions:</label>
              <br />
              <TextField
                multiline
                id="standard-basic"
                name="otherActions"
                variant="standard"
                disabled={!canEdit()}
                value={followUp?.otherActions}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(
                    editFollowUp({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                }}
              />
            </Stack>
          </Box>
        </FormGroup>
      </Box>
    </React.Fragment>
  );
}
export { ImmediateFollowUp };
