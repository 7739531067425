import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { toggleNoteAddModal, togglePatientRespNoteAddModal } from "../../../../../app/slices/VerificationModuleSlice";
import { addResponsibilityNote, addResponsibilityNoteCategory } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function PatientRespChangeNote(){
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector((state) => state.verificationModule.PatientRespNoteAddModal);
    const [note,setNote] = useState('');
    const [category, setCategory] = useState('');
    const saveNote=()=>{
        dispatch(addResponsibilityNote(note));
        dispatch(addResponsibilityNoteCategory(category));
        setNote('');
        setCategory('');
        dispatch(togglePatientRespNoteAddModal());
    }
    const disabled = note === '' || category === '';

    return <React.Fragment>
        <Dialog open={modalOpen} onClose={()=>setNote('')}>
            <DialogTitle>Patient Responsibility Change Note</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                <TextField label='Reason for Change' value={note} onChange={(e)=>setNote(e.target.value)} multiline 
                    InputLabelProps={{shrink:true}} sx={{width:'400px',marginTop:'10px'}} minRows='3'/>
                    <TextField
                        select
                        label="Category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        SelectProps={{
                            native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: '400px', marginTop: '10px' }}
                    >
                        <option value="">Select a category</option>
                        <option value="Patient paid other provider">Patient paid other provider</option>
                        <option value="Patient changed stay type">Patient changed stay type</option>
                        <option value="Additional procedure(s) added">Additional procedure(s) added</option>
                        <option value="Procedure(s) removed">Procedure(s) removed</option>
                        <option value="Change in payor">Change in payor</option>
                        <option value="Other">Other</option>
                    </TextField>                    
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { saveNote() }} disabled={disabled}>Submit</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}

export {PatientRespChangeNote}